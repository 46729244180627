#universe_container {
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: .90;
  right: -25%;
  cursor: pointer;
  filter: brightness(150%);
}

#universe-title {
  text-align: center;
  z-index: -10;
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translateX(-50%);
  font-family: Hack, monospace;
  opacity: 1;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 3.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 3.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 3.5s infinite ease-in-out;
  /* IE */
  animation: blink normal 3.5s infinite ease-in-out;
  /* Opera */

}

@media screen and (max-width: 430px) {
  #universe_container, #fullscr {
    display: none;
  }
}


